import dynamic from 'next/dynamic'
import { GetServerSideProps } from 'next/types'

import { default as AppBase } from '../App'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
import { getServerSideStoreInfo } from '../services/ssr'
import { IPage } from '@typesApp/cms'
import { serverSidePropsWrapper } from '@utils/SSR/wrapperGetServerProps'
import { PAGE_TYPES, getHeaders, setHeaders } from '@utils/httpHeadersUtils'
import { CRITICAL_PLACEMENT_LIMIT } from '@constants/cms'

const HomePage = dynamic(() => import('@views/Home'), {
  ssr: true,
})

interface HomeIndexProps {
  homePageInitial: IPage
}

const HomeIndex: React.FC<HomeIndexProps> = ({ homePageInitial }) => {
  return (
    <AppBase>
      <HomePage homePageInitial={homePageInitial} />
    </AppBase>
  )
}

export const getServerSideProps: GetServerSideProps<HomeIndexProps> = serverSidePropsWrapper(
  async (stateManager, context) => {
    const storeInfoManager = stateManager.get('storeInfo')
    const siteDataRes = storeInfoManager.getData()
    const { query } = context
    const previewDate = query?.previewDate as string
    const filterRulesLocaleOverride = query?.filterRulesLocaleOverride as string

    if (!siteDataRes) {
      throw new Error('store info data not found')
    }

    const { locale, res } = context
    const params = {
      storeId: siteDataRes.storeID,
      langId: siteDataRes?.langId ?? siteDataRes?.defaultLanguageID,
      locale,
      country: siteDataRes.country,
      previewDate,
      filterRulesLocaleOverride,
    }

    const homePageInitial = await cmsApiService.getHomePage({
      ...params,
      'section.main.limit': `${CRITICAL_PLACEMENT_LIMIT}`,
    })
    setHeaders(res, getHeaders(PAGE_TYPES.HOME_OR_STATIC_PAGE))
    return {
      props: {
        ...(await getServerSideStoreInfo(siteDataRes, locale)),
        homePageInitial,
      },
    }
  }
)

export default HomeIndex
